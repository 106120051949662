import React from "react";
import LongContent from "./LongContent";

function LegalPrivacy(props) {
   return (
      <LongContent>
         <h1 class="c5" id="h.1vvd2lfqcrn4"><span class="c16">Privacy Policy</span></h1>
         <h2 class="c5" id="h.bmdrire621x8"><span class="c2">General</span></h2>
         <p class="c14 title" id="h.ncxie3ciyt0d"><span class="c0">Product.st PTY LTD and its affiliates (hereinafter, &quot;the
            Company&quot;, &quot;we&quot;, &quot;us&quot; or &quot;our&quot;) are committed to protecting and respecting
            your privacy. In addition, we are subject to the Privacy Act 1988 and the National Privacy Principles which form
            part of that Act.</span></p>
         <p class="c3 title" id="h.ncxie3ciyt0d-1"><span class="c0">This Privacy Policy (together with our Terms and Services)
            governs our collection, processing and use of your Personal Information.</span></p>
         <p class="c3 title" id="h.ncxie3ciyt0d-2"><span class="c0">&quot;Personal Information&quot; is information which
            identifies you personally or by which your identity can reasonably be ascertained. This may include your name,
            address, e-mail address, and other contact details.</span></p>
         <p class="c3 title" id="h.ncxie3ciyt0d-3"><span class="c0">The purpose of this Privacy Policy is to inform you
            of:</span></p>
         <ol class="c11 lst-kix_a7teu4zfg6kw-0 start" start="1">
            <li class="c10 title li-bullet-0" id="h.ncxie3ciyt0d-4"><span class="c0">the kinds of Personal Information which we
               may collect about you and how it may be used;</span></li>
            <li class="c10 title li-bullet-0" id="h.ncxie3ciyt0d-5"><span class="c0">our use of information regarding IP
               Addresses and our use of cookies;</span></li>
            <li class="c10 title li-bullet-0" id="h.ncxie3ciyt0d-6"><span class="c0">how your Personal Information may be
               disclosed to third parties;</span></li>
            <li class="c10 title li-bullet-0" id="h.ncxie3ciyt0d-7"><span class="c0">your ability to access, correct, update and
               delete your Personal Information; and</span></li>
            <li class="c13 title li-bullet-0" id="h.ncxie3ciyt0d-8"><span class="c0">the security measures we have in place to
               prevent the loss, misuse, or alteration of Personal Information under our control.</span></li>
         </ol>
         <h2 class="c12" id="h.jr9g21igo9zf"><span class="c2">Gathering and Use of Personal Information</span></h2>
         <p class="c8 title" id="h.ncxie3ciyt0d-9"><span class="c0">We may collect your personal information if you use the site;
            open an account to use the platform; or perform any transactions on the platform.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-10"><span class="c0">We may also collect personal information about you from
            recordings of telephone conversations between us and you (or your representatives) and from publicly available
            registers.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-11"><span class="c0">Some of the personal information we collect is required to
            meet legal and regulatory obligations (e.g. to verify your identity). Otherwise, the personal information we
            collect is required to provide our services efficiently and effectively.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-12"><span class="c0">The types of personal information may include: your name;
            photographic identification; your address; phone number; e-mail address; banking details including account
            numbers; date of birth; and information about your transactions.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-13"><span class="c0">We may use your personal information for the following
            purposes:</span></p>
         <ol class="c11 lst-kix_3bg0yyrz3kld-0 start" start="1">
            <li class="c7 title li-bullet-0" id="h.ncxie3ciyt0d-14"><span class="c0">to allow you to open and operate an
               account</span></li>
            <li class="c7 title li-bullet-0" id="h.ncxie3ciyt0d-15"><span class="c0">to enable you to complete transactions on
               the platform</span></li>
            <li class="c7 title li-bullet-0" id="h.ncxie3ciyt0d-16"><span class="c0">to correspond with you in relation to your
               account and your transactions</span></li>
            <li class="c7 title li-bullet-0" id="h.ncxie3ciyt0d-17"><span class="c0">to analyse use of our site and the services
               we provide</span></li>
            <li class="c7 title li-bullet-0" id="h.ncxie3ciyt0d-18"><span class="c0">as required for legal and regulatory
               purposes, including for dispute resolution purposes</span></li>
            <li class="c7 title li-bullet-0" id="h.ncxie3ciyt0d-19"><span class="c0">to provide you with information about
               products, services and promotions that may be of interest to you, from ourselves and third parties</span>
            </li>
            <li class="c8 c15 title li-bullet-0" id="h.ncxie3ciyt0d-20"><span class="c0">for market research, e.g. surveying
               users&#39; needs and opinions on issues, such as our performance.</span></li>
         </ol>
         <p class="c4 title" id="h.ncxie3ciyt0d-21"><span class="c0">You should note that you are not obliged to give your
            personal information to the company. If you choose not to do so, we may not be able to provide our services, or
            your access to our services may be limited.</span></p>
         <h2 class="c12" id="h.6el6jwe7jy8m"><span class="c2">IP Addresses</span></h2>
         <p class="c1 title" id="h.ncxie3ciyt0d-22"><span class="c0">We may collect information about your computer. This
            includes where available your IP address, operating system and browser type. This is for system administration
            and to report aggregate information to our advertisers. It is statistical data about our users&#39; browsing
            actions and patterns and does not identify any individual.</span></p>
         <h2 class="c12" id="h.c7fj2s9s7rma"><span class="c2">Cookies</span></h2>
         <p class="c8 title" id="h.ncxie3ciyt0d-23"><span class="c0">We may use a browser feature known as a &lsquo;cookie&rsquo;
            which assigns a unique identifier to your computer.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-24"><span class="c0">Cookies are typically stored on your computer&#39;s hard
            drive. Information collected from cookies is used by us to evaluate the effectiveness of our site, analyse
            trends, and administer the platform.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-25"><span class="c0">Information collected from cookies allows us to determine
            for example which parts of our site are most visited, or any difficulties in accessing our site.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-26"><span class="c0">With this knowledge, we can improve the quality of your
            experience on the platform.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-27"><span class="c0">We also use cookies and/or a technology known as web bugs or
            clear GIFs. They are typically stored in emails to confirm your receipt and response to our emails. It also
            provides a more personalised experience when using our site.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-28"><span class="c0">We may use third party service provider(s), to assist us in
            better understanding the use of our site. Our service provider(s) may place cookies on the hard drive of your
            computer. It may receive information that we select on how visitors navigate our site, what products are
            browsed, and general transaction information.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-29"><span class="c0">Our service provider(s) analyses this information and
            provides us with aggregate reports. This information will be used to understand visitor interest in our site and
            how to better serve those interests. The information collected by our service provider(s) may be linked to and
            combined with information that we collect about you while you are using the site. Our service providers are
            contractually restricted from using information they receive from our site other than to assist us.</span></p>
         <p class="c4 title" id="h.ncxie3ciyt0d-30"><span class="c0">By using our site you agree that we may use cookies for the
            purposes set out above.</span></p>
         <h2 class="c12" id="h.t4pe2z9tt33"><span class="c2">Disclosure of Personal Information</span></h2>
         <p class="c8 title" id="h.ncxie3ciyt0d-31"><span class="c0">We use personal information for the purposes indicated at
            the time you provided us with the information. It is also used for purposes set out in this privacy policy
            and/or as otherwise permitted by law.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-32"><span class="c0">We may make the personal information available to our
            affiliates, agents, representatives, service providers and contractors for these purposes.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-33"><span class="c0">We may share the personal information with financial
            institutions or insurance companies as required for the purposes of the company&#39;s dealings with those
            institutions.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-34"><span class="c0">It may also be shared with other companies in the case of a
            merger, divestiture, or other corporate re-organization.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-35"><span class="c0">We may share users&#39; personal information with the
            Australian Securities &amp; Investments Commission; AUSTRAC; any financial dispute resolution scheme to which
            the company subscribes. This includes other law enforcement bodies; regulatory agencies; courts; arbitration
            bodies; and dispute resolution schemes. This may be both in Australia and internationally, as may be required by
            law.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-36"><span class="c0">If you request in writing, we may share your personal
            information with your nominated advisers. Except where disclosure of your personal information is required by
            law or requested by you, we will generally require any third party which receives, or has access to, personal
            information to protect it. Also to use it only to carry out the services they are performing for you or for us,
            unless otherwise required or permitted by law.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-37"><span class="c0">We will ensure that any such third party is aware of our
            obligations under this privacy policy.</span></p>
         <p class="c4 title" id="h.ncxie3ciyt0d-38"><span class="c0">We will take reasonable steps to ensure that contracts we
            enter with such third parties binds them to terms no less protective of any personal information disclosed to
            them than the obligations we undertake to you under this privacy policy, or which are imposed on us under
            applicable data protection laws.</span></p>
         <h2 class="c12" id="h.agj9ow5vanpq"><span class="c2">Access and Changing of Personal Information</span></h2>
         <p class="c8 title" id="h.ncxie3ciyt0d-39"><span class="c0">You have the right to access the personal information we
            hold about you.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-40"><span class="c0">You have the right to require the correction, updating and
            blocking of inaccurate and/or incorrect data by sending an email to us.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-41"><span class="c0">We will usually respond to your request within 14 days. You
            may also request the deletion or destruction of your personal information, your account details or your
            transaction details by sending an email to us.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-42"><span class="c0">The company will act on your request only when it is not
            inconsistent with its legal and regulatory obligations and compliance procedures.</span></p>
         <p class="c4 title" id="h.ncxie3ciyt0d-43"><span class="c0">Upon your written request, we will inform you of the use and
            general disclosure of your personal information. Depending on the nature of your request, there may be a minimal
            charge for accessing your personal information.</span></p>
         <h2 class="c12" id="h.bk3j3tlgs8ib"><span class="c2">Security</span></h2>
         <p class="c8 title" id="h.ncxie3ciyt0d-44"><span class="c0">We take reasonable steps to protect your personal
            information from misuse, loss, unauthorised access, modification or disclosure, including implementing
            appropriate security measures.</span></p>
         <p class="c4 title" id="h.ncxie3ciyt0d-45"><span class="c0">The security measures in place will, from time to time, be
            reviewed in line with legal and technical developments. However, we give no guarantee that such misuse, loss,
            unauthorised access, modification or disclosure will not occur.</span></p>
         <h2 class="c12" id="h.fo2nsia3cleb"><span class="c2">Retention of Personal Information</span></h2>
         <p class="c8 title" id="h.ncxie3ciyt0d-46"><span class="c0">We will hold your personal information only for as long as
            it is necessary for us to do so, having regard to the purposes described in this privacy policy and our own
            legal and regulatory requirements.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-47"><span class="c0">In general, personal information relating to your account is
            held for at least a period of five years after your account is closed.</span></p>
         <p class="c4 title" id="h.ncxie3ciyt0d-48"><span class="c0">Similarly, we usually retain information about transactions
            on your account for a period of five years from the date of the transaction. Personal information which is
            collected for other purposes will be discarded in accordance with our policies in place from time to
            time.</span></p>
         <h2 class="c12" id="h.3vigkotx0eie"><span class="c2">Links</span></h2>
         <p class="c8 title" id="h.ncxie3ciyt0d-49"><span class="c0">There may be links from our site to other sites and
            resources provided by third parties. This privacy policy applies only to our site. Accessing those third party
            sites or sources requires you to leave our site.</span></p>
         <p class="c6 title" id="h.ncxie3ciyt0d-50"><span class="c0">We do not control those third party sites or any of the
            content contained therein. You agree that we are in no way responsible or liable for any of those third party
            sites. This includes without limitation, their content, policies, failures, promotions, products, services or
            actions and/or any damages, losses, failures or problems caused by, related to or arising from those
            sites.</span></p>
         <p class="c4 title" id="h.ncxie3ciyt0d-51"><span class="c0">We encourage you to review all policies, rules, terms and
            regulations, including the privacy policies, of each site that you visit.</span></p>
         <h2 class="c12" id="h.8au4e4h2292a"><span class="c2">Changes</span></h2>
         <p class="c1 title" id="h.ncxie3ciyt0d-52"><span class="c0">Our policies, content, information, promotions, disclosures,
            disclaimers and features may be revised, modified, updated, and/or supplemented at any time and without prior
            notice at the sole and absolute discretion of the company. If we change this privacy policy, we will take steps
            to notify all users by a notice on our website and will post the amended privacy policy on the website.</span>
         </p>
         <h2 class="c17" id="h.l1g7tpw9pdrl"><span class="c2">Contact Us</span></h2>
         <p class="c18 title" id="h.ah1zvilcbsxq"><span class="c0">If you have any questions, comments, or concerns regarding our
            privacy policy and/or practices as it or they relate to the platform, please contact us. If you have a complaint
            about how your personal information has been used, please contact us in the first instance. If we cannot resolve
            your complaint to your satisfaction, you may complain to the Privacy Commissioner who may investigate your
            complaint further.</span></p>
         <p class="c20 title" id="h.ncxie3ciyt0d-53"><span class="c9"></span></p>
      </LongContent>
   );
}

export default LegalPrivacy;
