import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import LinkMui from "@material-ui/core/Link";
import { Link } from "./../util/router";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useAuth } from "./../util/auth";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
    paperItems: {
        minHeight: "300px",
    },
    featured: {
        backgroundColor:
            theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
    },
    starFeatured: {
        color: theme.palette.warning.main,
    },
    cardContent: {
        padding: theme.spacing(3),
    },
}));

const ColorTextTypography = withStyles({
    root: {
      color: "#2CFCC7"
    }
  })(Typography);


function DashboardInfo(props) {
    const classes = useStyles();
    const auth = useAuth();

    return (
        <>
            <Grid container={true} spacing={4}>
                <Grid item={true} xs={12} md={6}>
                    <Card>
                        <CardContent className={classes.cardContent}>
                            <Typography variant="h7" paragraph={true}>
                                <>Identity verification is complete. Your now ready to Deposit AUD</>
                            </Typography>
                            <Box>
                                <ColorTextTypography variant="h6">
                                    <strong>Your Balances:</strong>
                                </ColorTextTypography>
                                <Box>
                                    <Typography variant="subtitle1" paragraph={true}>
                                        <>Australian Dollars:  <strong>${auth.user.balance} AUD</strong></><br />
    
                                    </Typography>
                                </Box>
                                <Typography variant="subtitle2" paragraph={false}>
                                Disclaimer: 
                                </Typography>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

        </>
    );
}

export default DashboardInfo;
