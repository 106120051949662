import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import PricingPage from "./pricing";
import DashboardPage from "./dashboard";
import AuthPage from "./auth";
import SettingsPage from "./settings";
import LegalPage from "./legal";
import LegalAgreement from "../components/LegalAgreement";
import { Switch, Route, Router } from "./../util/router";
import Auth0Callback from "./auth0-callback";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
import { AuthProvider } from "./../util/auth";
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from "./../util/theme";
import { QueryClientProvider } from "./../util/db";

function App(props) {

  return (
    <QueryClientProvider>
      <ThemeProvider>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirectUri={window.location.origin}   
        audience={`https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`}
      >
        <AuthProvider>
          <Router>
            <>
              <Navbar
                color="default"
                logo="logo.png"
                logoInverted="indigo-pyro-logo.mp4"
              />

              <Switch>
                <Route exact path="/" component={IndexPage} />

                {/* <Route exact path="/about" component={AboutPage} /> */}

                {/* <Route exact path="/faq" component={FaqPage} /> */}

                <Route exact path="/contact" component={ContactPage} />

                {/* <Route exact path="/pricing" component={PricingPage} /> */}

                <Route exact path="/dashboard" component={DashboardPage} />

                {/* <Route exact path="/auth/:type" component={AuthPage} /> */}

                <Route
                  exact
                  path="/settings/:section"
                  component={SettingsPage}
                />

                <Route exact path="/legal/:section" component={LegalPage} />
                {/* <Route exact path="/legalagreement" component={LegalAgreement} /> */}

                {/* <Route exact path="/auth0-callback" component={Auth0Callback} /> */}

                <Route component={NotFoundPage} />
              </Switch>


              <Footer
                bgColor="light"
                size="normal"
                bgImage=""
                bgImageOpacity={1}
                description="Indigo Pyro"
                copyright={`Copyright © 2023 Indigo Pyro | All Rights Reserved`}
                logo="logo.png"
                logoInverted="indigo-pyro-logo-footer.mp4"
                sticky={true}
              />
            </>
          </Router>
        </AuthProvider>
        </Auth0Provider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
