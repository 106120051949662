import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import DashboardInfo from "./DashboardInfo";
import DashboardNewEvent from "./DashboardNewEvent";
import DashboardEventView from "./DashboardEventView";
import { Link, useRouter } from "./../util/router";
import { useAuth } from "./../util/auth";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useEventsByOwner } from "./../util/db";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: theme.spacing(3),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function DashboardSection(props) {
  const classes = useStyles();

  const auth = useAuth();
  const router = useRouter();

  const {
    data: userEvents,
    status: eventsStatus,
    error: eventsError,
  } = useEventsByOwner(auth.user.uid);
  
  console.log(userEvents);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  if (!auth.user.email_verified) {
    return (
      <Section
        bgColor={props.bgColor}
        size={props.size}
        bgImage={props.bgImage}
        bgImageOpacity={props.bgImageOpacity}
      >
        <Container>
          <Typography variant="h4" paragraph={true}>
            <strong>Please check your email to verify your email address. If this is already done, then reload this page.</strong>
          </Typography>
        </Container>
      </Section>
    )
  }

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} variant="scrollable" allowScrollButtonsMobile={true}
              scrollButtons="on" aria-label="dashboard tabs" TabIndicatorProps={{ style: { backgroundColor: "#2CFCC7" } }}
              >
                <Tab label="Dashboard" />
                <Tab label="New Event" />
                {eventsStatus !== "loading" && userEvents && userEvents.length > 0 && (
                  userEvents.map((obj, i) => (
                    <Tab label={`Event ${i}`} />
                  ))
                )}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>

             <DashboardInfo />

          </TabPanel>
            <TabPanel value={value} index={1}>
              <DashboardNewEvent /><br />
            </TabPanel>
            {eventsStatus !== "loading" && userEvents && userEvents.length > 0 && (
                  userEvents.map((obj, i) => (
                    <TabPanel value={value} index={1 + i + 1}>
                      {/* <>{JSON.stringify(obj)}</> */}
                      <DashboardEventView eventObj={obj} />
                    </TabPanel>
                  ))
              )
            }
        </Box>

      </Container>
    </Section>
  );
}

export default DashboardSection;
