import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";

function IndexPage(props) {


  return (
    <>
      <Meta />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Pyrotechnics for Your Special Day!"
        subtitle={'Are you ready to ignite your celebrations and paint the sky with breathtaking colors? Look no further! Indigo Pyro is your one-stop destination for an awe-inspiring, tailor-made pyrotechnic experience that will leave you and your guests speechless. Say goodbye to traditional firework displays and say hello to a truly interactive and unforgettable event!'}
        image="./indigo-pyro-landing-vid1.mp4"
        buttonText="Contact Us"
        buttonColor="primary"
        buttonPath="/contact"
      />
      {/* <ClientsSection
        bgColor="light"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      /> */}
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Creating Unforgettable Memories"
        subtitle=""
      />
      {/* <TestimonialsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      /> */}
      {/* <NewsletterSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive our latest articles and feature updates"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      /> */}
    </>
  );
}

export default IndexPage;
