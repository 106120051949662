import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      // marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    // maxWidth: 300,
    // margin: "3em auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
    borderRadius: "10%",
    marginTop: '1em',
    // paddingRight: '1em',
    transform: "scale(1.3)",
  },
  video: {
    height: "auto",
    maxWidth: "100%",
    borderRadius: "10%",
    marginTop: '1em',
    paddingRight: '1em',
    transform: "scale(1.1)",
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Empowering You with the \"Remote Control\"",
      description:
        "What sets us apart is our innovative approach to fireworks displays. We put the power in your hands with our revolutionary \"remote control\" experience. Yes, you heard that right! You and your audience become the conductors of the night's pyrotechnic symphony. With our cutting-edge technology, you get to decide when each firework launches, creating a dynamic and interactive show that will keep everyone engaged.",
      // image: "./landing_buying_eth_2.png",
      video: "indigo-pyro-landing-vid-remote-control.mp4"
    },
    {
      title: "The Ultimate Pyrotechnic Experience",
      description:
        "At Indigo Pyro, we understand that every celebration is unique. That's why we take pride in crafting bespoke firework displays that perfectly match the essence of your special occasion. Whether it's your dream wedding, a milestone birthday, a joyous general celebration, or a corporate event that demands a touch of pyro – we've got you covered!",
      image: "./indigo-pyro-landing-feature1.png",
    },
  ];

  const whyItems = [
    {
      title: "Innovation",
      description:
        "We believe in pushing the boundaries of pyrotechnics to elevate your event to new heights. Our \"remote control\" technology is an industry-first, setting a new standard for interactivity and engagement.",
    },
    {
      title: "Safety First",
      description:
        "Your safety and the safety of your guests are paramount to us. We strictly adhere to all safety regulations and employ the best practices in the industry.",
    },
    {
      title: "Experienced Team",
      description:
        "Our team of pyrotechnic experts brings years of experience and creativity to the table. They are passionate about delivering a stunning show that exceeds your expectations.",
    },
    {
      title: "Tailored Perfection",
      description:
        "We understand the importance of personalization. Your event is special, and we take pride in crafting firework displays that reflect your unique style and preferences.",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                {item.image ? <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure> 
                : 
                <video src={item.video} alt={item.title} className={classes.video} autoPlay muted loop/>
                }
              </Grid>
            </Grid>
          ))}<br/><br/>
          <Typography variant="h4" gutterBottom={true} align="center">
            Why Choose Indigo Pyro?
          </Typography>
          {whyItems.map((item, index) => (
            <Grid
              // className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={8}
              key={index}
            >
              <Grid item={true} xs={12} md={12}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              
            </Grid>
          ))}<br/><br/><br/>
          <Typography variant="h4" gutterBottom={true} align="center">
            Tell Us About Your Event
          </Typography><br/>
          <Typography variant="subtitle1">
            Are you ready to turn your event into an extraordinary celebration of light, color, and joy? Get in touch with us today for a free consultation. Our team at Indigo Pyro is excited to partner with you and bring your vision to life. Together, let's create memories that will sparkle forever.
          </Typography>

          <br/>
          <iframe src="https://forms.monday.com/forms/embed/fc4064db7c87b6a95adb0fb3391fbd3e?r=apse2" width="100%" height="1100" style={{border: '0', boxShadow: "5px 5px 56px 0px rgba(0,0,0,0.25)", margin: 0}}></iframe>
          <br/><br/><br/><br/>
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
