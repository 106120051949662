import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useForm } from "react-hook-form";
import { useAuth } from "./../util/auth";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import { Link } from "./../util/router";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  paperItems: {
      minHeight: "300px",
  },
  featured: {
      backgroundColor:
          theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
      color: theme.palette.warning.main,
  },
  cardContent: {
      padding: theme.spacing(3),
  },
}));

function SettingsGeneral(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [pending, setPending] = useState(false);

  const { register, handleSubmit, errors } = useForm();

  // const onSubmit = (data) => {
  //   // Show pending indicator
  //   setPending(true);

  //   return auth
  //     .updateProfile(data)
  //     .then(() => {
  //       // Set success status
  //       props.onStatus({
  //         type: "success",
  //         message: "Your profile has been updated",
  //       });
  //     })
  //     .catch((error) => {
  //       if (error.code === "auth/requires-recent-login") {
  //         props.onStatus({
  //           type: "requires-recent-login",
  //           // Resubmit after reauth flow
  //           callback: () => onSubmit(data),
  //         });
  //       } else {
  //         // Set error status
  //         props.onStatus({
  //           type: "error",
  //           message: error.message,
  //         });
  //       }
  //     })
  //     .finally(() => {
  //       // Hide pending indicator
  //       setPending(false);
  //     });
  // };

  return (
    <>
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12} md={12}>
          <Card>
            <CardContent className={classes.cardContent}>
              <Box>
                <Typography variant="h6" paragraph={true}>
                  <strong></strong>
                </Typography>
                <Typography component="div">
                  <div>
                    You are signed in as: <strong>{auth.user.email}</strong>
                  </div><br/>
                  <div>
                    Your KYC/Identity verification status: <strong>{auth.user.kyc}</strong>.
                  </div> <br/>
                  <div>
                    Verified email: <strong>{(auth.user.email_verified == false ? `No ${auth.user.email_verified}` : "Yes")}</strong>.
                  </div>

                  {/* <div>
                    You can change your account info{` `}
                    in{` `}
                    <LinkMui component={Link} to="/settings/general">
                      <strong>Settings</strong>
                    </LinkMui>
                    .
                  </div> */}

                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
    // <form onSubmit={handleSubmit(onSubmit)}>
    //   <Grid container={true} spacing={2}>
    //     <Grid item={true} xs={12}>
    //       <TextField
    //         variant="outlined"
    //         type="text"
    //         label="Name"
    //         name="name"
    //         placeholder="Name"
    //         defaultValue={auth.user.name}
    //         error={errors.name ? true : false}
    //         helperText={errors.name && errors.name.message}
    //         fullWidth={true}
    //         inputRef={register({
    //           required: "Please enter your name",
    //         })}
    //       />
    //     </Grid>
    //     <Grid item={true} xs={12}>
    //       <TextField
    //         variant="outlined"
    //         type="email"
    //         label="Email"
    //         name="email"
    //         placeholder="user@example.com"
    //         defaultValue={auth.user.email}
    //         error={errors.email ? true : false}
    //         helperText={errors.email && errors.email.message}
    //         fullWidth={true}
    //         inputRef={register({
    //           required: "Please enter your email",
    //         })}
    //       />
    //     </Grid>
    //     <Grid item={true} xs={12}>
    //       <Button
    //         variant="contained"
    //         color="primary"
    //         size="large"
    //         type="submit"
    //         disabled={pending}
    //         fullWidth={true}
    //       >
    //         {!pending && <span>Save</span>}

    //         {pending && <CircularProgress size={28} />}
    //       </Button>
    //     </Grid>
    //   </Grid>
    // </form>
  );
}

export default SettingsGeneral;
