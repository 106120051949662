import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Table from "@material-ui/core/List";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
// import CreateDepositModal from "./CreateDepositModal";
import { useAuth } from "./../util/auth";
import { useBuyHistoryByOwner, useEventsByEventID } from "./../util/db";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  table: {
    minWidth: 750
  },
  tableRowRoot: {
    "&$tableRowSelected, &$tableRowSelected:hover": {
      backgroundColor: "seagreen"
    }
  },
  tableRowSelected: {
    backgroundColor: "seagreen"
  }
}));

const headCells = [
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Firework Type"
    },
    { id: "Requested At", numeric: false, disablePadding: false, label: "Requested At" },
    { id: "LaunchID", numeric: true, disablePadding: false, label: "Launch ID" },
    { id: "Remaining", numeric: true, disablePadding: false, label: "Remaining Fireworks" },
    { id: "UserID", numeric: true, disablePadding: false, label: "User ID" },
    { id: "EventID", numeric: true, disablePadding: false, label: "Event ID" },
];

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    // highlight:
    //   theme.palette.type === "light"
    //     ? {
    //         color: theme.palette.primary.main,
    //         backgroundColor: alpha(
    //           theme.palette.primary.light,
    //           theme.palette.action.selectedOpacity
    //         )
    //       }
    //     : {
    //         color: theme.palette.text.primary,
    //         backgroundColor: theme.palette.primary.dark
    //       },
    title: {
      flex: "1 1 100%"
    }
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, totalItems } = props;
  
    return (
      <Toolbar
        // className={clsx(classes.root, {
        //   [classes.highlight]: numSelected > 0
        // })}
      >
        {numSelected > 0 ? (
          <Typography
            className={classes.title}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected out of {totalItems} launch requests
          </Typography>
        ) : (
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Fireworks Requested To Launch
          </Typography>
        )}
  
        {/* {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )} */}
      </Toolbar>
    );
  };
  

function DashboardEventView(props) {
  const classes = useStyles();

  const auth = useAuth();

//   const {
//     data: buyRequests,
//     status: buyRequestsStatus,
//     error: buyRequestsError,
//   } = useBuyHistoryByOwner(auth.user.uid);

  const {
    data: launchRequests,
    status: launchRequestsStatus,
    error: launchRequestsError,
  } = useEventsByEventID(props.eventObj.eventId);

  const [creatingItem, setCreatingItem] = useState(false);

  const [updatingItemId, setUpdatingItemId] = useState(null);

  const itemsAreEmpty = !launchRequests || launchRequests.length === 0;

  let rowCount = ( !launchRequests ? 0 : launchRequests.length);

  const [selected, setSelected] = React.useState([]);
  const isSelected = (launchUUID) => selected.indexOf(launchUUID) !== -1;

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = launchRequests.map((n) => n.launchUUID);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);

    // getSavedSelectedItemsFromStorage();
  };

  const getSavedSelectedItemsFromStorage = () => {
    let savedSelectedItems = localStorage.getItem("selectedItems");

    if (savedSelectedItems != null) {
      savedSelectedItems = JSON.parse(savedSelectedItems);
      if (savedSelectedItems != null && savedSelectedItems.length > 0) {
        // const savedSelectedIDs = savedSelectedItems.map((n) => n.launchUUID);
        setSelected(savedSelectedItems);
        return savedSelectedItems;
      }
    }
  };

  useEffect(() => {
 
    getSavedSelectedItemsFromStorage();
    
    // window.addEventListener('keydown', e => {
    //   if (e.key == " " || e.key == "Space") {
    //     if (launchRequests != null && launchRequests.length > 0) {
    //       // handleClick(e, launchRequests[(launchRequests.length-1) - selected.length].launchUUID);
    //     }      
    //   }
    // })
  }, []);

  useEffect(() => {
    // storing input name
    localStorage.setItem("selectedItems", JSON.stringify(selected));
  }, [selected]);

  // const canUseStar =
  //   auth.user.planIsActive &&
  //   (auth.user.planId === "pro" || auth.user.planId === "business");

  // const handleStarItem = (item) => {
  //   if (canUseStar) {
  //     updateItem(item.id, { featured: !item.featured });
  //   } else {
  //     alert("You must upgrade to the pro or business plan to use this feature");
  //   }
  // };

  return (
    <>
      {launchRequestsError && (
        <Box mb={3}>
          <Alert severity="error">{launchRequestsError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h7">Your Firework Launch Requests for event {props.eventObj.eventId}</Typography><br/><br/>
          <Typography variant="a">
            {
              props.eventObj.participantsUUIDs.map((pUUID, i) =>
                <a
                  href={`http://localhost:8080/api/fireworks?uid=${pUUID}`}
                  target="_blank"
                  rel="noreferrer"
                >Unique Participant Link {i+1} <br/>
                </a>
              )
            }
          </Typography>
          
        </Box>
        <Divider />

        {(launchRequestsStatus === "loading" || itemsAreEmpty) && (
          <Box py={5} px={3} align="center">
            {launchRequestsStatus === "loading" && <CircularProgress size={32} />}

            {launchRequestsStatus !== "loading" && itemsAreEmpty && (
              <>No history yet. Buy some crypto to see your buy history.</>
            )}
          </Box>
        )}

        {launchRequestsStatus !== "loading" && launchRequests && launchRequests.length > 0 && (
            <>
            <EnhancedTableToolbar numSelected={selected.length} totalItems={rowCount} />
          <TableContainer component={Paper} >
            <Table sx={{ minWidth: 650 }} aria-label="withdraw table">
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={selected.length > 0 && selected.length < rowCount}
                            checked={rowCount > 0 && selected.length === rowCount}
                            onChange={handleSelectAllClick}
                            color="primary"
                            inputProps={{ "aria-label": "select all launch requests" }}
                        /> 
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? "right" : "left"}
                            padding={headCell.disablePadding ? "none" : "normal"}
                        >
                            {headCell.label}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
              <TableBody >
                {launchRequests.map((item, index) => {
                  const isItemSelected = isSelected(item.launchUUID);

                    const {firecrackers, fountains, mortars} = item;
                return (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    classes={{
                        root: classes.tableRowRoot,
                        selected: classes.tableRowSelected
                      }}
                    selected={isItemSelected}
                    onClick={(event) => handleClick(event, item.launchUUID)}
                  >
                    {/* <TableCell component="th" scope="row">
                    {item.cryptoAmount} {item.selectedCoin.toUpperCase()} @ ${item.quotedPrice.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                    </TableCell>
                    <TableCell>${item.totalSpend.toLocaleString(undefined, { maximumFractionDigits: 2 })}</TableCell>
                    <TableCell>{(item.createdAt != null ? item.createdAt.toDate().toDateString() : null)}</TableCell> */}
                    <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                        //   inputProps={{ "aria-labelledby": labelId }}
                          color="primary"
                        />
                    </TableCell>
                    <TableCell>{(item.selectedFireworkType != null ? item.selectedFireworkType.toUpperCase() : null)}</TableCell>
                    <TableCell>{`${(item.createdAt != null ? item.createdAt.toDate() : new Date()).toLocaleTimeString()}  ${(item.createdAt != null ? item.createdAt.toDate() : new Date()).toDateString()}`}</TableCell>
                    <TableCell>{`${(item.launchUUID != null ? item.launchUUID : null)}`}</TableCell>
                    <TableCell width={'15%'}>
                        <p>{`Firecrackers: ${firecrackers}`}</p>
                        <p>{`Fountains: ${fountains}`}</p>
                        <p>{`Mortars: ${mortars}`}</p>
                    </TableCell>
                    <TableCell>{`${(item.userUUID != null ? item.userUUID : null)}`}</TableCell>
                    <TableCell>{`${(item.eventUUID != null ? item.eventUUID : null)}`}</TableCell>
                    {/* <TableCell>{JSON.stringify(item)}</TableCell> */}
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </TableContainer>
          </>
          // <List disablePadding={true}>
          //   {buyRequests.map((item, index) => (
          //     <ListItem
          //       key={index}
          //       divider={index !== buyRequests.length - 1}
          //       className={item.featured ? classes.featured : ""}
                
          //     >
          //       <ListItemText>{item.cryptoAmount} {item.selectedCoin.toUpperCase()} @ ${item.quotedPrice.toLocaleString(undefined, {maximumFractionDigits: 2})}</ListItemText>
          //       <ListItemText>${item.totalSpend.toLocaleString(undefined, {maximumFractionDigits: 2})}</ListItemText>
          //       <ListItemText>{(item.createdAt!= null ? item.createdAt.toDate().toDateString() : null)}</ListItemText>
          //       <ListItemText>{item.status}</ListItemText>
          //       {/* <ListItemSecondaryAction>
          //         <IconButton
          //           edge="end"
          //           aria-label="star"
          //           onClick={() => handleStarItem(item)}
          //           className={item.featured ? classes.starFeatured : ""}
          //         >
          //           <StarIcon />
          //         </IconButton>
          //         <IconButton
          //           edge="end"
          //           aria-label="update"
          //           onClick={() => setUpdatingItemId(item.id)}
          //         >
          //           <EditIcon />
          //         </IconButton>
          //         <IconButton
          //           edge="end"
          //           aria-label="delete"
          //           onClick={() => deleteItem(item.id)}
          //         >
          //           <DeleteIcon />
          //         </IconButton>
          //       </ListItemSecondaryAction> */}
          //     </ListItem>
          //   ))}
          // </List>
        )}
                {JSON.stringify(props.eventObj)}

      </Paper>

      {/* {creatingItem && <CreateDepositModal onDone={() => setCreatingItem(false)} />} */}

      {/* {updatingItemId && (
        <CreateDepositModal
          id={updatingItemId}
          onDone={() => setUpdatingItemId(null)}
        />
      )} */}
    </>
  );
}

export default DashboardEventView;
