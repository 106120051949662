import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useForm  } from "react-hook-form";
// import CreateDepositModal from "./CreateDepositModal";
import { useAuth } from "./../util/auth";
import { saveNewPyroEvent, saveNewParticipant, updateUser } from "./../util/db";

const useStyles = makeStyles((theme) => ({
  // paperItems: {
  //   minHeight: "300px",
  // },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  textField: {
    // input label when focused
    "& label.Mui-focused": {
      color: "#f5fffa"
    },
    // focused color for input with variant='standard'
    "& .MuiInput-underline:after": {
      borderBottomColor: "#f5fffa"
    },
    // focused color for input with variant='filled'
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: "#f5fffa"
    },
    // focused color for input with variant='outlined'
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "#f5fffa"
      }
    }
  }
}));

function DashboardNewEvent(props) {
  const classes = useStyles();
  const theme = useTheme();

  const auth = useAuth();
  const { register, handleSubmit, errors, reset } = useForm();
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const [amountPreview, setAmountPreview] = useState(0);

  const [selectedFiringType, setSelectedFiringType] = React.useState('Single_Device');
  const handleChange = (event) => {
    setSelectedFiringType(event.target.value);
  };

//   useEffect(() => {
//     if (prices != null && prices.length > 0) {
//       let prceObj = prices[0].prices.filter(x => { return x.result.marketId == selectedCoin.toUpperCase() });
//       setQuotedPrice(prceObj[0].result.lastPrice);
//     }
//   }, [prices, selectedCoin]);

  
    const onSubmit = (data) => {
        try {
            setPending(true);
            console.log(data);

            data.participants = parseInt(data.participants);
            data.firecrackers = parseInt(data.firecrackers);
            data.fountains = parseInt(data.fountains);
            data.mortars = parseInt(data.mortars);

            const participantUUID = crypto.randomUUID();
            const eventUUID = crypto.randomUUID();

            const {firecrackers, fountains, mortars, participants} = data;

            let participantsArray = Array(participants);
            participantsArray = participantsArray.fill(0);
            participantsArray = participantsArray.map((v) => crypto.randomUUID());
            // participantsArray = participantsArray.fill(crypto.randomUUID(), 0 , participants);

            let newEventObj = {
                owner: auth.user.uid,
                status: "EVENT_NOT_STARTED",
                eventId: eventUUID,
                participantsUUIDs: participantsArray,
                participantUUID,
                ...data
            };
            saveNewPyroEvent(newEventObj);
            // updateUser(auth.user.uid, {balance: (auth.user.balance - buyRequestObj.totalSpend)});

            // const {firecrackers, fountains, mortars, participants} = data;
            // const remaining = firecrackers + fountains + mortars;
            /**
             * Create each participant collection: genreate a new uuid for them and save each firework into their collection linking back to the eventID
             * 
             */

            /**
             * For each items in participantsArray create a unique participant obj and their share of the fireworks
             */
            const shareOfMortars = Math.floor(mortars / participants);
            const shareOfFirecrackers = Math.floor(firecrackers / participants);
            const shareOfFountains = Math.floor(fountains / participants);
            const remaining = shareOfMortars + shareOfFountains + shareOfFirecrackers;

            participantsArray.map((pUUID) => {
              const newParticipantObj = {
                eventUUID,
                remaining,
                participantUUID: pUUID,
                firecrackers: shareOfFirecrackers,
                fountains: shareOfFountains,
                mortars: shareOfMortars
              };

              saveNewParticipant(newParticipantObj);
            });


            // // const participantUUID = crypto.randomUUID();
            // const newParticipantObj = {
            //     eventUUID,
            //     remaining,
            //     participantUUID,
            //     firecrackers,
            //     fountains,
            //     mortars
            // }
            // saveNewParticipant(newParticipantObj);

            setPending(false);
            setAmountPreview(0);
            reset();

            setFormAlert({
                type: "success",
                message: `http://localhost:8080/api/fireworks?uid=${participantUUID}`,
            });
        } catch (error) {
            setFormAlert({
                type: "error",
                message: "An error has occurred. If the issue persists, please don't hesitate to contact our support team for assistance.",
            });
            setPending(false);
        }
    };

  return (
    <>
      {formAlert && (
        <Box mb={1}>
          <Alert severity={formAlert.type}>{formAlert.message}</Alert>
          <a
              href={formAlert.message}
              target="_blank"
              rel="noreferrer"
            >Unique Link
            </a>
        </Box>
      )}

    <Grid container={true} spacing={2}>
      <Grid item={true} xs={12} md={6}>
      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
              <Box
                component={Typography}
                variant="h5"
                sx={{ color: "#2CFCC7"}}
              >
                <strong><u>New Pyro Event</u></strong>
              </Box>
              {/* <Typography variant="h5"></Typography> */}
              <Typography variant="h7"><strong>Notes</strong></Typography>
            </Box>
        <Divider />
      
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container={true} spacing={3} >
              <Grid item={true} xs={12} style={{marginLeft: 10, marginRight: 10 }}>
                <Select
                  autoFocus={true}
                  value={selectedFiringType}
                  name="selectedFiringType"
                  error={errors.selectedFiringType ? true : false}
                  helperText={errors.selectedFiringType && errors.selectedFiringType.message}
                  fullWidth={true}
                  label="selectedFiringType"
                  onChange={handleChange}
                  // inputRef={register({ //Causes issues when in prod build
                  //   required: "Please select a coin",
                  // })}
                >
                  <MenuItem value={"Single_Device"} name="singleDevice">  Single Device</MenuItem>
                  <MenuItem value={"Multi_Device"} name="multiDevice">  Multi Device</MenuItem>
                </Select>
              </Grid>
              <Grid item={true} xs={12} style={{marginLeft: 10, marginRight: 10 }}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { step: "any" } }}
                  label={`Participants - Denominated in ${selectedFiringType} number of unique device users`}
                  name="participants"
                  error={errors.participants ? true : false}
                  helperText={errors.participants && errors.participants.message}
                  onChange={(v)=> {
                    // console.log(v.target.value == "");
                    // console.log(isNaN(v.target.value));
                    // if (v.target.value != "" && !isNaN(v.target.value))
                    //   setAmountPreview(v.target.value);
                    // else 
                    //   setAmountPreview(0);
                  }}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter an amount",
                    pattern: {
                      value: /^[.0-9]+$/,
                      message: 'Only numbers are allowed.'
                    },
                    validate: {
                      required: value => {
                        // if ((value * quotedPrice) > auth.user.balance ) 
                        //   return 'Amount is more than user\'s AUD balance.';
                        // if ((selectedCoin == "BTC-AUD" && value < 0.0001))
                        //   return 'Amount must be >= 0.0001';
                        // if ((selectedCoin == "ETH-AUD" && value < 0.001))
                        //   return 'Amount must be >= 0.001';

                        if (value <= 0) {
                          return 'Participants must be >= 1'
                        }
                        return true;
                      },
                    }
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} style={{marginLeft: 10, marginRight: 10 }}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { step: "any" } }}
                  label={`Mortars for each unique user`}
                  name="mortars"
                  error={errors.mortars ? true : false}
                  helperText={errors.mortars && errors.mortars.message}
                  onChange={(v)=> {
                    // console.log(v.target.value == "");
                    // console.log(isNaN(v.target.value));
                    // if (v.target.value != "" && !isNaN(v.target.value))
                    //   setAmountPreview(v.target.value);
                    // else 
                    //   setAmountPreview(0);
                  }}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter an amount",
                    pattern: {
                      value: /^[.0-9]+$/,
                      message: 'Only numbers are allowed.'
                    },
                    validate: {
                      required: value => {
                        // if ((value * quotedPrice) > auth.user.balance ) 
                        //   return 'Amount is more than user\'s AUD balance.';
                        // if ((selectedCoin == "BTC-AUD" && value < 0.0001))
                        //   return 'Amount must be >= 0.0001';
                        // if ((selectedCoin == "ETH-AUD" && value < 0.001))
                        //   return 'Amount must be >= 0.001';
                        return true;
                      },
                    }
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} style={{marginLeft: 10, marginRight: 10 }}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { step: "any" } }}
                  label={`Fountains for each unique user`}
                  name="fountains"
                  error={errors.cakes ? true : false}
                  helperText={errors.cakes && errors.cakes.message}
                  onChange={(v)=> {
                    // console.log(v.target.value == "");
                    // console.log(isNaN(v.target.value));
                    // if (v.target.value != "" && !isNaN(v.target.value))
                    //   setAmountPreview(v.target.value);
                    // else 
                    //   setAmountPreview(0);
                  }}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter an amount",
                    pattern: {
                      value: /^[.0-9]+$/,
                      message: 'Only numbers are allowed.'
                    },
                    validate: {
                      required: value => {
                        // if ((value * quotedPrice) > auth.user.balance ) 
                        //   return 'Amount is more than user\'s AUD balance.';
                        // if ((selectedCoin == "BTC-AUD" && value < 0.0001))
                        //   return 'Amount must be >= 0.0001';
                        // if ((selectedCoin == "ETH-AUD" && value < 0.001))
                        //   return 'Amount must be >= 0.001';
                        return true;
                      },
                    }
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} style={{marginLeft: 10, marginRight: 10 }}>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { step: "any" } }}
                  label={`Firecrackers for each unique user`}
                  name="firecrackers"
                  error={errors.cakes ? true : false}
                  helperText={errors.cakes && errors.cakes.message}
                  onChange={(v)=> {
                    // console.log(v.target.value == "");
                    // console.log(isNaN(v.target.value));
                    // if (v.target.value != "" && !isNaN(v.target.value))
                    //   setAmountPreview(v.target.value);
                    // else 
                    //   setAmountPreview(0);
                  }}
                  fullWidth={true}
                  inputRef={register({
                    required: "Please enter an amount",
                    pattern: {
                      value: /^[.0-9]+$/,
                      message: 'Only numbers are allowed.'
                    },
                    validate: {
                      required: value => {
                        // if ((value * quotedPrice) > auth.user.balance ) 
                        //   return 'Amount is more than user\'s AUD balance.';
                        // if ((selectedCoin == "BTC-AUD" && value < 0.0001))
                        //   return 'Amount must be >= 0.0001';
                        // if ((selectedCoin == "ETH-AUD" && value < 0.001))
                        //   return 'Amount must be >= 0.001';
                        return true;
                      },
                    }
                  })}
                />
              </Grid>
              <Grid item={true} xs={12} style={{marginLeft: 10, marginRight: 10 }}>
                <Paper className={classes.paperItems}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      padding={2}
                    >
                      <Box paddingRight={2}>
                      </Box>
                      <Box paddingLeft={0} marginRight={0}>
                        <span>{selectedFiringType} </span>
                      </Box>
                    </Box>
                </Paper>
                <Divider />
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    padding={2}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="submit"
                      style={theme.buttonStyle}
                      disabled={pending}
                    ><strong>
                      {!pending && <span>Submit Buy</span>}

                      {pending && <CircularProgress size={28} />}</strong>
                    </Button>
                  </Box>
                <Divider />
              </Grid>
            </Grid>
          </form>
      </Paper>
      </Grid>
        <Grid item={true} xs={12} md={6}>
          <Paper className={classes.paperItems}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              padding={2}
              margin={0}
            >
              <Box paddingRight={2}>
                <span >Your Bitcoin Balance: <strong>{auth.user.btc} BTC</strong></span><br />
              </Box>
              <Box paddingLeft={2}>
                <span>Your Ethereum Balance: <strong>{parseFloat(auth.user.eth).toFixed(4)} ETH</strong></span>
              </Box>
            </Box>
            <Divider />

          </Paper>
          <Card>
            <CardContent className={classes.cardContent}>
              <Box>
                <Typography variant="h6" paragraph={true}>
                  <u>Buy Crypto:</u><br />
                  <>1) Once your deposit is cleared, it will show under "Available AUD Balance".</><br />
                  <>2) Select the crypto you want to buy.</><br />
                  {/* <>3) Enter the crypto address you want your crypto purchase to be sent to.</><br /> */}
                  <>3) Enter the amount of crypto you want to buy.</><br />
                  <>4) Then submit your buy request.</><br />
                  {/* <>Your crypto will then be transferred to your entered crypro address. </><br /> */}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    </>
  );
}

export default DashboardNewEvent;
